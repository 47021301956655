import React, { useState } from "react"
import {FaBars, FaTimes} from "react-icons/fa";
import {Link} from 'react-scroll';

const NavBar = () => {

  const [nav, setNav] = useState(false);

  // Sections array
  const links = [
    {
      id: 1,
      link: "home"
    },
    {
      id: 2,
      link: "about"
    },
    {
      id: 3,
      link: "technical"
    },
    {
      id: 4,
      link: "projects"
    },
    {
      id: 5,
      link: "contact"
    },
  ];

  return (
    // Primary container
    <div className="flex justify-between items-center w-full h-14 px-4 text-white bg-black fixed z-50">
      <div>
        <h1 className="cursor-pointer text-3xl font-signature ml-2">
          <Link to="home" smooth duration={500}>
            Yasin Sabik
          </Link>
        </h1>
      </div>

      {/* Sections */}
      <ul className="hidden md:flex">
        {links.map(({id, link}) => (
          <li 
            key={id} 
            className="px-4 cursor-pointer capitalize font-medium text-gray-500 hover:scale-105 duration-200"
          >
            <Link to={link} smooth duration={500}>
              {link}
            </Link>
          </li>
        ))}
      </ul>

      <div 
        onClick={() => setNav(!nav)} 
        className="cursor-pointer pr-4 z-10 text-gray-500 md:hidden"
      >
        {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>

      {/* Smooth scroll function */}
      {nav && (
        <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-slate-800 text-gray-500">
          {links.map(({id, link}) => (
            <li 
              key={id} 
              className="px-4 cursor-pointer capitalize py-6 text-4xl"
            >
              <Link 
                onClick={() => setNav(!nav)} 
                to={link} 
                smooth 
                duration={500}
              >
                {link}
              </Link>
            </li>
          ))}
      </ul>
      )}
    </div>
  );
};

export default NavBar;
